import React, { useContext, useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  TablePagination,
  Button,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./OrganizationDetailsStyles";

import { ROUTE_USERS, ROUTE_ORGANIZATIONS } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import messages from "./messages";
import { toJS } from "mobx";

const initialState = {
  dialog: false,
  deleteorgdialog: false,
  deleteUser: null,
  orgcode: "",
  description: "",
  users: [],
  ssoGroups: [],
  error: "",
  users_page: 0,
  users_rowsPerPage: 5,
  ssoGroups_page: 0,
  ssoGroups_rowsPerPage: 5,
};

function reducer(state, action) {
  switch (action.type) {
    case "dialog":
      return { ...state, dialog: action.dialog };
    case "deleteorgdialog":
      return { ...state, deleteorgdialog: action.deleteorgdialog };
    case "deleteUser":
      return { ...state, deleteUser: action.deleteUser };
    case "orgcode":
      return { ...state, orgcode: action.orgcode };
    case "description":
      return { ...state, description: action.description };
    case "users":
      return { ...state, users: action.users };
    case "ssoGroups":
      return { ...state, ssoGroups: action.ssoGroups };
    case "error":
      return { ...state, error: action.error };
    case "users_page":
      return { ...state, users_page: action.users_page };
    case "users_rowsPerPage":
      return { ...state, users_rowsPerPage: action.users_rowsPerPage };
    case "ssoGroups_page":
      return { ...state, ssoGroups_page: action.ssoGroups_page };
    case "ssoGroups_rowsPerPage":
      return { ...state, ssoGroups_rowsPerPage: action.ssoGroups_rowsPerPage };
    default:
      throw new Error();
  }
}

const OrganizationDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    organizationStore: { organizationModel: { loadOrganization, loadOrganizations, activeOrganization, deleteUserFromOrganization, deleteOrganization, saveOrganization } },
    applicationStore: { toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [orgcode, setOrgcode] = useState("");
  const [description, setDesciption] = useState("");
  const [error, setError] = useState("");

  function handleOpen(userid = null) {
    if (userid) {
      dispatch({ type: "deleteUser", deleteUser: userid });
    }
    dispatch({ type: "dialog", dialog: !state.dialog });
  }
  function handleOpenDeleteOrg() {
    return dispatch({ type: "deleteorgdialog", deleteorgdialog: !state.deleteorgdialog });
  }

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        await loadOrganization(params.id);
      } catch (e) {
        console.log("Error::usergroup detail fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::usergroup detail fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchOrganization();
  }, []);

  useEffect(() => {
    if (activeOrganization !== null) {
      dispatch({ type: "orgcode", orgcode: activeOrganization.orgcode });
      dispatch({
        type: "description",
        description: activeOrganization.description,
      });
      dispatch({ type: "users", users: activeOrganization.users });
      dispatch({ type: "ssoGroups", ssoGroups: activeOrganization.ssoGroups });
    }
  }, [activeOrganization]);

  async function deleteUserFromOrg(userId) {
    try {
      await deleteUserFromOrganization(userId, activeOrganization);
      handleOpen();
    } catch (e) {
      console.log("Error::organization detail delete user from group: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::organization detail delete user from group: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function handleChangePageUser(event, page) {
    dispatch({ type: "users_page", users_page: page });
  }

  function handleChangeRowsPerPageUser(event) {
    dispatch({
      type: "users_rowsPerPage",
      users_rowsPerPage: event.target.value,
    });
  }

  function handleChangePageSsoGroup(event, page) {
    dispatch({ type: "ssoGroups_page", ssoGroups_page: page });
  }

  function handleChangeRowsPerPageSsoGroup(event) {
    dispatch({
      type: "ssoGroups_rowsPerPage",
      ssoGroups_rowsPerPage: event.target.value,
    });
  }

  const emptyRowsUsers = state.users_rowsPerPage - Math.min(state.users_rowsPerPage, state.users?.length - state.users_page * state.users_rowsPerPage);

  const emptyRowsssoGroups = state.ssoGroups_rowsPerPage - Math.min(state.ssoGroups_rowsPerPage, state.ssoGroups?.length - state.ssoGroups_page * state.ssoGroups_rowsPerPage);

  async function deleteThisOrg() {
    try {
      await deleteOrganization(activeOrganization.id);
      handleOpenDeleteOrg();
      await loadOrganizations();
      push(ROUTE_ORGANIZATIONS);
    } catch (e) {
      console.log("Error::organization details delete: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::organization details delete: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  useEffect(() => {
    if (activeOrganization !== null) {
      setOrgcode(activeOrganization.orgcode);
      setDesciption(activeOrganization.description);
    }
  }, [activeOrganization]);
  async function submitOrg() {
    let organization = {
      orgcode: orgcode,
      description: description,
    };
    try {
      await saveOrganization(activeOrganization.id, organization);
      setError(formatMessage(messages.organizationdetailmessagesuccess));
    } catch (e) {
      console.log("Error::organization details save: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::organization details save: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitOrg() : null;
  }
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h6" color="inherit">
                  {formatMessage(messages.detailstitle)}
                </Typography>
              </Grid>
              <Grid item>
                <Button data-test-id="delete-org" color="secondary" className={classes.button} onClick={handleOpenDeleteOrg}>
                  {formatMessage(messages.organizationdetaildelete)}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <form id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="orgcode"
                  label={formatMessage(messages.detailscode)}
                  type="text"
                  fullWidth
                  required
                  value={orgcode}
                  onChange={(e) => setOrgcode(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="description"
                  label={formatMessage(messages.detailsdescription)}
                  type="text"
                  fullWidth
                  required
                  value={description}
                  onChange={(e) => setDesciption(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                  {formatMessage(messages.organizationdetailsave)}
                </Button>
              </Grid>
              <Grid item>
                <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                  {formatMessage(messages.organizationdetaildialogcancel)}
                </Button>
              </Grid>
              <Grid item>
                <Typography>{error}</Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
      <Grid container spacing={2} className={classes.doubleboxes}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="h6" color="inherit">
                      {formatMessage(messages.detailslinkeduserstitle)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={formatMessage(messages.detailslinkeduserstooltip)}>
                      <IconButton data-test-id="btn-add-user" aria-label={formatMessage(messages.detailslinkeduserstooltip)} onClick={() => push(ROUTE_USERS)}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Table className={classes.table} data-test-id="tbl-user">
              <TableHead>
                <TableRow>
                  <TableCell>{formatMessage(messages.detailslinkeduserstableheadername)}</TableCell>
                  <TableCell padding="none" colSpan={2}>
                    {formatMessage(messages.detailslinkeduserstableheaderemail)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.users?.slice(state.users_page * state.users_rowsPerPage, state.users_page * state.users_rowsPerPage + state.users_rowsPerPage).map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell data-test-id="user-name">{user.firstName + " " + user.lastName}</TableCell>
                      <TableCell padding="none" data-test-id="user-email">
                        {user.email}
                      </TableCell>
                      <TableCell align={"right"}>
                        <Tooltip title={formatMessage(messages.detailslinkeduserstabledeletetooltip)}>
                          <IconButton aria-label={formatMessage(messages.detailslinkeduserstabledeletetooltip)} onClick={() => handleOpen(user.id)} data-test-id="btn-delete-user">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRowsUsers > 0 && (
                  <TableRow style={{ height: 48 * emptyRowsUsers }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={state.users?.length}
              rowsPerPage={state.users_rowsPerPage}
              page={state.users_page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePageUser}
              onChangeRowsPerPage={handleChangeRowsPerPageUser}
              labelRowsPerPage={"x"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="h6" color="inherit">
                      {formatMessage(messages.detailsactievessogroepentitle)}
                    </Typography>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Table data-test-id="tbl-ssoGroup">
              <TableHead>
                <TableRow>
                  <TableCell>{formatMessage(messages.detailsssogrouptableheadername)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.ssoGroups
                  ?.slice(state.ssoGroups_page * state.ssoGroups_rowsPerPage, state.ssoGroups_page * state.ssoGroups_rowsPerPage + state.ssoGroups_rowsPerPage)
                  .map((ssoGroup) => {
                    return (
                      <TableRow key={ssoGroup.id}>
                        <TableCell
                          data-test-id="ssoGroup"
                          style={{
                            backgroundColor: ssoGroup.organizationId === null ? "rgba(239, 239, 239, 0.3)" : "#fff",
                            color: ssoGroup.organizationId === null ? "#c6c6c6" : "rgba(0, 0, 0, 0.87)",
                            padding: "8px",
                          }}
                        >
                          {ssoGroup.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRowsssoGroups > 0 && (
                  <TableRow style={{ height: 48 * emptyRowsssoGroups }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={state.ssoGroups?.length}
              rowsPerPage={state.ssoGroups_rowsPerPage}
              page={state.ssoGroups_page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePageSsoGroup}
              onChangeRowsPerPage={handleChangeRowsPerPageSsoGroup}
              labelRowsPerPage={"x"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
            <CustomDialog
              data-test-id="delete-popup"
              open={state.dialog}
              setopen={handleOpen}
              title={formatMessage(messages.organizationdetaildialogtitle)}
              type={"danger"}
              confirm={formatMessage(messages.organizationdetaildialogconfirm)}
              confirmaction={() => deleteUserFromOrg(state.deleteUser)}
              cancel={formatMessage(messages.organizationdetaildialogcancel)}
            >
              {formatMessage(messages.organizationdetaildialogtext)}
            </CustomDialog>
            <CustomDialog
              data-test-id="deleteorg-popup"
              open={state.deleteorgdialog}
              setopen={handleOpenDeleteOrg}
              title={formatMessage(messages.deletedialogtitle)}
              type={"danger"}
              confirm={formatMessage(messages.deletedialogconfirm)}
              confirmaction={deleteThisOrg}
              cancel={formatMessage(messages.organizationdetaildialogcancel)}
            >
              {formatMessage(messages.organizationdetaildialogtext2)}
            </CustomDialog>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

OrganizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(OrganizationDetails));
