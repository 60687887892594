import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { withRouter } from "react-router";
import classNames from "classnames";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, withWidth, Icon } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./SidebarStyles";

import messages from "./messages";

import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DomainIcon from "@material-ui/icons/Domain";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { ROUTE_BASE, ROUTE_ORGANIZATIONS, ROUTE_SSO_GROUPS, ROUTE_USERGROUPS, ROUTE_USERS } from "../../../routes/RouteList";
import { toJS } from "mobx";

require("../../../layout/orbit.css");

// include staticContext and uiStore because there will be a console error => https://stackoverflow.com/a/49358913/7751585
const Sidebar = hooksObserver(({ match, classes, staticContext, uiStore, intl: { formatMessage }, ...other }) => {
  const {
    routingStore: { location, push },
    applicationStore: { apps },
  } = useContext(StoresContext);

  const url = location.pathname;
  const splittedUrl = url.split("/");
  let goBackPath = "";
  splittedUrl.map((value, index) => {
    if (value !== "" && index < splittedUrl.length - 1) {
      goBackPath += "/" + value;
    }
  });
  goBackPath === "" ? (goBackPath = ROUTE_BASE) : null;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
          <Icon className={"icon-logo-orbit-black"} />
          &nbsp;&nbsp;
          {formatMessage(messages.appname)}
        </ListItem>
        <ListItem
          className={classNames(classes.item, classes.itemCategory, classes.mainLink, ROUTE_BASE === location.pathname && classes.itemActiveItem)}
          data-test-id="sidebar_home"
          onClick={() => push(ROUTE_BASE)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {formatMessage(messages.dashboard)}
          </ListItemText>
        </ListItem>
        <Fragment key={"Userbeheer"}>
          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              {formatMessage(messages.gebruikersbeheer)}
            </ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            key={"Gebruikers"}
            className={classNames(classes.item, classes.itemActionable, ROUTE_USERS === "/" + match.url.split("/")[1] && classes.itemActiveItem)}
            data-test-id="sidebar_users"
            onClick={() => push(ROUTE_USERS)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                dense: classes.dense,
              }}
            >
              {formatMessage(messages.gebruikers)}
            </ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            key={"Gebruikersgroepen"}
            className={classNames(classes.item, classes.itemActionable, ROUTE_USERGROUPS === "/" + match.url.split("/")[1] && classes.itemActiveItem)}
            data-test-id="sidebar_usergroups"
            onClick={() => push(ROUTE_USERGROUPS)}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                dense: classes.dense,
              }}
            >
              {formatMessage(messages.gebruikersgroepen)}
            </ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            key={"Organisaties"}
            className={classNames(classes.item, classes.itemActionable, ROUTE_ORGANIZATIONS === "/" + match.url.split("/")[1] && classes.itemActiveItem)}
            data-test-id="sidebar_organizations"
            onClick={() => push(ROUTE_ORGANIZATIONS)}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                dense: classes.dense,
              }}
            >
              {formatMessage(messages.organizations)}
            </ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            key={"SsoGroups"}
            className={classNames(classes.item, classes.itemActionable, ROUTE_SSO_GROUPS === "/" + match.url.split("/")[1] && classes.itemActiveItem)}
            data-test-id="sidebar_ssogroups"
            onClick={() => push(ROUTE_SSO_GROUPS)}
          >
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                dense: classes.dense,
              }}
            >
              {formatMessage(messages.ssogroups)}
            </ListItemText>
          </ListItem>
        </Fragment>
        {apps.map(({ id, name }) => {
          return (
            <Fragment key={id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  <FormattedMessage {...(messages[name + "appdisplayname"] ? messages[name + "appdisplayname"] : messages.unknownappdisplayname)} />
                </ListItemText>
              </ListItem>
              {name === "municipality" ? (
                <Fragment>
                  <ListItem
                    button
                    dense
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      name !== undefined && name !== null && match.url.split("/")[1] === name && match.url.split("/")[2] === "municipalities" && classes.itemActiveItem,
                    )}
                    data-test-id={"sidebar_" + name}
                    onClick={() => push(ROUTE_BASE + name + "/municipalities")}
                  >
                    <ListItemIcon>
                      <DomainIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        dense: classes.dense,
                      }}
                    >
                      Gemeenten
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    dense
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      name !== undefined && name !== null && match.url.split("/")[1] === name && match.url.split("/")[2] === "crab" && classes.itemActiveItem,
                    )}
                    data-test-id={"sidebar_" + name}
                    onClick={() => push(ROUTE_BASE + name + "/crab")}
                  >
                    <ListItemIcon>
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        dense: classes.dense,
                      }}
                    >
                      CRAB-Adressen
                    </ListItemText>
                  </ListItem>
                </Fragment>
              ) : (
                <Fragment>
                  <ListItem
                    button
                    dense
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      name !== undefined && name !== null && match.url.split("/")[1] === name && match.url.split("/")[2] === "profiles" && classes.itemActiveItem,
                    )}
                    data-test-id={"sidebar_" + name}
                    onClick={() => push(ROUTE_BASE + name + "/profiles")}
                  >
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        dense: classes.dense,
                      }}
                    >
                      {formatMessage(messages.profielen)}
                    </ListItemText>
                  </ListItem>
                </Fragment>
              )}
            </Fragment>
          );

          return null;
        })}
      </List>
    </Drawer>
  );
});
export default injectIntl(withRouter(withWidth()(withStyles(styles)(Sidebar))));
