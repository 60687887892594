var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, Icon, InputAdornment, Link, TextField, Typography } from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import Mail from "@material-ui/icons/Mail";
import Send from "@material-ui/icons/Send";
import styles from "./LoginStyles";
import { withStyles } from "@material-ui/styles";
require("../style/logo.css");
const firstCharacterUpperCase = (str) => {
    return (str === null || str === void 0 ? void 0 : str.length) ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
const Login = ({ classes, messages, urlForgotPassword, authStrategies = [{ isSSO: false, label: "Login", type: "user-pass" }], doLogin, doLoginSSO, }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const ssoStrategies = authStrategies.filter((t) => t.isSSO);
    const formStategy = authStrategies.find((t) => !t.isSSO);
    const validateEmail = () => {
        if (email.length < 1)
            return false;
        return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };
    const handleValidation = () => {
        return email.length > 0 && !validateEmail() && password.length > 0;
    };
    const handleKeyPress = (e) => {
        if (/enter/gi.test(e.key) && handleValidation()) {
            handleClick();
        }
    };
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { success, error } = yield doLogin(email, password);
            console.log({ success, error });
        }
        catch (error) {
            setMessage((_a = messages.notFound) !== null && _a !== void 0 ? _a : "Combinatie e-mail en wachtwoord niet gekend.");
        }
    });
    return (React.createElement(Grid, { container: true, spacing: 0, alignItems: "center", justify: "center", style: { minHeight: "90vh" } },
        React.createElement(Card, { className: classes.card },
            React.createElement(CardHeader, { avatar: React.createElement(Icon, { className: "icon-logo-orbit-black" }), title: messages.appTitle }),
            React.createElement(CardContent, null,
                React.createElement(Grid, { container: true, spacing: 4 },
                    formStategy && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { align: "center" }, messages.loginTitle),
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(TextField, { required: true, id: "email", inputProps: {
                                    "data-test-id": "email",
                                }, label: messages.labelEmail, helperText: messages.labelEmailHelper, value: email, onChange: (e) => setEmail(e.target.value), onKeyDown: handleKeyPress, error: validateEmail(), margin: "normal", fullWidth: true, InputProps: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Mail, null))),
                                } }),
                            React.createElement(TextField, { required: true, id: "password", inputProps: {
                                    "data-test-id": "password",
                                }, label: messages.labelPassword, value: password, type: "password", onChange: (e) => setPassword(e.target.value), onKeyDown: handleKeyPress, margin: "normal", fullWidth: true, InputProps: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Lock, null))),
                                } })),
                        React.createElement(Box, { display: "flex", className: classes.action, style: { flexDirection: "column" } },
                            React.createElement(Box, null,
                                React.createElement(Typography, { variant: "caption", "data-test-id": "error-message", className: classes.loginError }, message)),
                            React.createElement(Box, { display: "flex", style: { marginTop: 15, alignItems: "center", justifyContent: "right" } },
                                React.createElement(Typography, { variant: "button" },
                                    React.createElement(Link, { "data-test-id": "forgot-link", href: urlForgotPassword, target: "_blank", className: classes.link }, messages.forgotPassword)),
                                React.createElement(Button, { id: "btn_login", "data-test-id": "submit", onClick: (e) => handleClick(), disabled: !handleValidation(), color: "primary", className: classes.button, variant: "contained" },
                                    React.createElement(Send, { className: classes.extendedIcon }),
                                    messages.button))))),
                    (ssoStrategies === null || ssoStrategies === void 0 ? void 0 : ssoStrategies.length) > 0 && doLoginSSO && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { align: "center", style: { marginBottom: 15 } }, firstCharacterUpperCase(`${formStategy ? `${messages.loginSSOSeperator} ` : ""}${messages.loginSSOTitle}:`)),
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center", justify: "center" }, ssoStrategies.map((authStrategy) => (React.createElement(Grid, { item: true, xs: 12, key: authStrategy.type },
                            React.createElement(Button, { color: "primary", variant: "contained", className: classes.button, fullWidth: true, onClick: () => doLoginSSO(authStrategy.type) }, authStrategy.label))))))))))));
};
export default withStyles(styles)(Login);
