/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "login.title",
    defaultMessage: "Aanmelden...",
  },
  labelEmail: {
    id: "login.label.email",
    defaultMessage: "E-mail",
  },
  labelEmailHelper: {
    id: "login.label.email.helper",
    defaultMessage: "voornaam.naam@police.belgium.eu",
  },
  labelPassword: {
    id: "login.label.password",
    defaultMessage: "Paswoord",
  },
  button: {
    id: "login.button",
    defaultMessage: "Aanmelden",
  },
  forgotPassword: {
    id: "login.forgotPassword",
    defaultMessage: "Paswoord vergeten",
  },
  appName: {
    id: "login.appname",
    defaultMessage: "Geoplatform | Admin",
  },
  loginDescription: {
    id: "login.description",
    defaultMessage: "Log in om door te gaan",
  },
  loginDescriptionOptionalSeperator: {
    id: "login.description.optional.seperator",
    defaultMessage: "of",
  },
  loginDescriptionSso: {
    id: "login.description.sso",
    defaultMessage: "ga verder met",
  },
});
