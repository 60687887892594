import React from "react";
import { AppBar, IconButton, Toolbar, Typography, Icon, withStyles } from "@material-ui/core";
import classNames from "classnames";
import styles from "./HeaderStyles.js";
require("../style/logo.css");
const Header = ({ classes, sideBarOpen, setSideBarOpen, applicationTitle, SearchComponent, headerFabs }) => {
    return (React.createElement(AppBar, { position: "fixed", className: classNames(classes.appBar, {
            [classes.appBarShift]: sideBarOpen,
        }) },
        React.createElement(Toolbar, null,
            React.createElement(IconButton, { id: "tour-sidebar", className: classes.menuButton, color: "inherit", "aria-label": "Open drawer", "data-test-id": "btn-toggle-sidebar", onClick: () => setSideBarOpen(!sideBarOpen) },
                React.createElement(Icon, { className: "icon-logo-orbit-black" })),
            React.createElement(Typography, { className: classes.title, variant: "h6", color: "inherit", noWrap: true, "data-test-id": "header-title" }, applicationTitle),
            React.createElement("div", { className: classes.grow }),
            SearchComponent && React.createElement(SearchComponent, null),
            React.createElement("div", { className: classes.grow }),
            headerFabs && headerFabs.map((Fab) => Fab))));
};
export default withStyles(styles)(Header);
