import React, { useContext, useState } from "react";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, Button, TextField } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./AddOrganizationStyles";

import { ROUTE_ORGANIZATIONS } from "../../../routes/RouteList";

import messages from "./messages.js";

const AddOrganization = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const {
    organizationStore: { organizationModel: { addOrganization } },
    uiStore: { locale },
    authStore: { forceIsLoggedInFalse },
    routingStore: { push },
    applicationStore: { toggleSnackError },
  } = useContext(StoresContext);

  const [error, setError] = useState("");
  const [orgcode, setOrgcode] = useState("");
  const [description, setDescription] = useState("");

  async function submitOrganization() {
    let org = {
      orgcode,
      description,
    };
    try {
      await addOrganization(org);
      setError("");
      push(ROUTE_ORGANIZATIONS);
    } catch (e) {
      console.log("Error::add organization: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::add organization: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitOrganization() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.addorganisationtitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="orgcode"
                inputProps={{
                  "data-test-id": "orgcode",
                }}
                label={formatMessage(messages.addorgcode)}
                type="text"
                fullWidth
                required
                value={orgcode}
                onChange={(e) => setOrgcode(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="description"
                inputProps={{
                  "data-test-id": "description",
                }}
                label={formatMessage(messages.addorgdescription)}
                type="text"
                fullWidth
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                {formatMessage(messages.addorganizationsave)}
              </Button>
            </Grid>
            <Grid item>
              <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_ORGANIZATIONS)}>
                {formatMessage(messages.addorganizationcancel)}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(AddOrganization));
